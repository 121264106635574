import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { createGlobalStyle } from 'styled-components';

const App = dynamic(() => import('src/app/components/App/index'), { ssr: false });

const AppGlobalStyle = createGlobalStyle`
  body {
    width: 100vw;
    min-height: 100vh; /* fallback for browswers that don't support dvh */
    min-height: 100dvh;
  }
  html {
    font-size: 62.5%;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

export default function CatchAll() {
  const { asPath, isReady } = useRouter();
  const isClearRxPage = typeof window !== 'undefined' ? window.location.host.includes('clearrx.co') : false;

  return (
    <>
      <Head>
        <title key="default-title">{isClearRxPage ? 'ClearRx' : 'Capsule | A better, smarter, kinder pharmacy'}</title>
        {isReady && !asPath.startsWith('/native') && (
          <script src="https://www.google.com/recaptcha/api.js?render=6LcyrVEfAAAAACITXKWQOR_9gP5otCO406KbZEvu" />
        )}
      </Head>

      <div id="app">
        <AppGlobalStyle />
        {typeof window !== 'undefined' && <App />}
      </div>
    </>
  );
}
